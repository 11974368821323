import { Controller } from "@hotwired/stimulus"

class SidebarController extends Controller {
  static targets = []

  connect() {
    document.addEventListener("openSidebar", (event) => {
      document
        .querySelectorAll(".sidebar-left")
        .forEach((el) => el.classList.add("hidden"))
      document
        .querySelectorAll(".sidebar-left")
        .forEach((el) => el.classList.remove("flex"))
      const sidebar = document.getElementById(`sidebar-${event.detail.id}`)
      sidebar.classList.toggle("hidden")
      sidebar.classList.toggle("flex")
    })
  }

  closeSidebar(event) {
    event.preventDefault()
    const sidebar = event.currentTarget.parentNode.parentNode.parentNode
    sidebar.classList.toggle("hidden")
    sidebar.classList.toggle("flex")
  }
}

export default SidebarController
