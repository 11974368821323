import { Controller } from "@hotwired/stimulus"
import postData from "./post"

class VoucherController extends Controller {
  static targets = ["voucherCode", "voucherCodeError"]

  submitVoucher() {
    this.voucherCodeErrorTarget.innerText = ""
    const data = { vouchercode: this.voucherCodeTarget.value }
    const errorBlock = this.voucherCodeErrorTarget

    postData(this.data.get("addVoucherToCartApi"), data).then((json) => {
      if (
        json["reason"] &&
        json["reason"] ===
          "Votre panier n'est pas admissible à un code de réduction"
      ) {
        errorBlock.innerText =
          "Votre panier ne respecte pas le montant minimum pour ce code promo."
        return
      }

      if (json["non_field_errors"]) {
        if (json["non_field_errors"].includes("Voucher code unknown")) {
          errorBlock.innerText = "Ce code promo n'est pas reconnu"
        } else if (json["non_field_errors"][0].includes("expiré")) {
          errorBlock.innerText = "Ce code est expiré"
        } else {
          console.log("Unknown error")
        }
      } else {
        document.dispatchEvent(new CustomEvent("voucherAdded"))
      }
    })
  }

  removeVoucher() {
    const data = { vouchercode: this.voucherCodeTarget.value }
    postData(this.data.get("removeVoucherFromCartApi"), data).then((json) => {
      document.dispatchEvent(new CustomEvent("voucherAdded"))
    })
  }
}

export default VoucherController
