import { Controller } from "@hotwired/stimulus"
import postData from "./post"
import patchData from "./patch"
import deleteData from "./delete"

class BasketController extends Controller {
  static targets = [
    "amount",
    "nbItems",
    "details",
    "detailsLines",
    "totalOrderAmount",
    "loginForm",
    "registerForm",
    "shippingAmount",
    "totalAmount",
    "paymentForm",
    "voucherBlock",
    "offerBlock",
    "voucherForm",
    "edenredChoice",
    "edenredChoiceLabel",
  ]

  _updateDetails(jsonData) {
    let html = "<ul>"
    jsonData.forEach(function (line) {
      html += `<li class="p-2 grid grid-cols-5 gap-4">
    <div>
        <img src="${line["product"]["images"][0]["original"]}">
    </div>
    <div class="col-span-3">
        ${line["product"]["title"]}<br>
        <div data-controller="product"
         data-product-initial-quantity="${line["quantity"]}"
         data-product-id="${line["product"]["id"]}" class="flex">
            
            <div class="flex bg-green-pure border-2 border-black justify-between font-bold">
                    <button class="px-1 py-2"
                            data-action="product#removeFromCart"
                            data-product-id="${line["product"]["id"]}">-</button>
                    <div class="px-1 py-2" data-product-target="quantity">${line["quantity"]}</div>
                    <button class="px-1 py-2"
                            data-action="product#addToCart"
                            data-product-id="${line["product"]["id"]}">+</button>
                </div>
            
            
         </div>
    </div>
    <div>
        ${line["price_incl_tax"]}€
    </div>
    </li><hr>`
    })
    html += "</ul>"
    this.detailsLinesTarget.innerHTML = html
  }

  _updateBasketAmounts(jsonData) {
    this.totalOrderAmountTarget.innerText = jsonData["total_incl_tax"] + "€"
    this.shippingAmountTarget.innerText =
      jsonData["shipping_charges"]["incl_tax"] + "€"
    if (this.hasTotalAmountTarget) {
      this.totalAmountTarget.innerText =
        jsonData["order_total"]["incl_tax"] + "€"
    }
    this.amountTarget.innerText = jsonData["order_total"]["incl_tax"] + "€"
    this.nbItemsTarget.innerText = jsonData["lines"].length
  }

  toogleDetails(event) {
    event.preventDefault()
    this.detailsTarget.classList.toggle("hidden")
    this.detailsTarget.classList.toggle("flex")
  }

  toogleLogin(event) {
    event.preventDefault()
    this.loginFormTarget.classList.toggle("hidden")
    this.loginFormTarget.classList.toggle("flex")
  }
  toogleRegister(event) {
    event.preventDefault()
    this.registerFormTarget.classList.toggle("hidden")
    this.registerFormTarget.classList.toggle("flex")
  }

  tooglePaymentForm(shouldShowForm) {
    if (!this.hasPaymentFormTarget) {
      return
    }

    if (shouldShowForm) {
      this.paymentFormTarget.classList.remove("hidden")
    } else {
      this.paymentFormTarget.classList.add("hidden")
    }
  }

  _displayPaymentMethod(totalAmount) {
    if (totalAmount > 25.0) {
      this.edenredChoiceTarget.disabled = true
      this.edenredChoiceLabelTarget.classList.add("text-gray-darker")
    } else {
      this.edenredChoiceTarget.disabled = false
      this.edenredChoiceLabelTarget.classList.remove("text-gray-darker")
    }
  }

  _syncAllPickers(jsonData) {
    jsonData.forEach(function (line) {
      const event = new CustomEvent("setProductQuantity", {
        detail: {
          id: line["product"]["id"],
          quantity: line["quantity"],
          previousQuantity: line["quantity"],
        },
      })
      document.dispatchEvent(event)
    })
  }

  _get_offer_text(offerNames) {
    let offers = ""
    offerNames.forEach((offer) => {
      let name = ""
      if (offer === "Full meal offer") {
        name = "Formule entrée/plat/dessert (-1€) ✅"
      }
      if (offer === "Meal offer") {
        name = "Formule plat + entrée ou dessert (-0.5€) ✅"
      }
      offers += name
    })
    return offers
  }

  _showDiscounts(json) {
    var voucherNames = json["voucher_discounts"].map(function (item) {
      return item.voucher.code
    })

    if (voucherNames.length > 0) {
      this.voucherBlockTarget.classList.remove("hidden")
      this.voucherBlockTarget.querySelector("#message").innerText =
        "Code promo : " + voucherNames.join(", ")
      this.voucherFormTarget.classList.add("hidden")
    } else {
      this.voucherBlockTarget.classList.add("hidden")
      this.voucherFormTarget.classList.remove("hidden")
    }

    var offerNames = json["offer_discounts"].map(function (item) {
      return item.name
    })

    if (offerNames.length > 0) {
      this.offerBlockTarget.classList.remove("hidden")
      this.offerBlockTarget.innerText = this._get_offer_text(offerNames)
    } else {
      this.offerBlockTarget.classList.add("hidden")
    }
  }

  _updatePanel(json) {
    this._updateDetails(json["lines"])
    this._updateBasketAmounts(json)
    this.tooglePaymentForm(Boolean(json["lines"].length))
    this._displayPaymentMethod(json["order_total"]["incl_tax"])
    this._syncAllPickers(json["lines"])
    this._showDiscounts(json)
  }

  _addToCart(productId) {
    const data = {
      url: `${this.data.get("productApiUrl")}${productId}/`,
      quantity: 1,
    }

    postData(this.data.get("addToCartApi"), data).then((json) => {
      if (json["reason"]) {
        document.dispatchEvent(
          new CustomEvent("displayMessage", { detail: json["reason"] })
        )
        this._fetchBasketAndUpdatePanel()
      } else {
        this._updatePanel(json)
      }
    })
  }

  _fetchBasketAndUpdatePanel() {
    fetch(this.data.get("apiUrl"), { method: "GET" })
      .then((response) => response.json())
      .then((json) => {
        this._updatePanel(json)
      })
  }

  _removeFromCart(productId, quantity) {
    const basketApiUrl = this.data.get("apiUrl")
    fetch(basketApiUrl, { method: "GET" })
      .then((response) => response.json())
      .then((json) => {
        const lineForProduct = json["lines"].find((obj) => {
          return obj.product.id == productId
        })
        if (quantity == 0) {
          deleteData(lineForProduct.url).then(() => {
            this._fetchBasketAndUpdatePanel()
          })
        } else {
          patchData(lineForProduct.url, { quantity: quantity }).then(() => {
            this._fetchBasketAndUpdatePanel()
          })
        }
      })
  }

  connect() {
    this._fetchBasketAndUpdatePanel()

    document.addEventListener("setProductQuantity", (event) => {
      if (event.detail.previousQuantity < event.detail.quantity) {
        this._addToCart(event.detail.id)
      } else if (event.detail.previousQuantity > event.detail.quantity) {
        this._removeFromCart(event.detail.id, event.detail.quantity)
      }
    })
    document.addEventListener("voucherAdded", () => {
      this._fetchBasketAndUpdatePanel()
    })
  }
}

export default BasketController
