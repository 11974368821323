import Cookies from "js-cookie"

export default async function deleteData(url = "") {
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken"),
    },
  })
  return response
}
