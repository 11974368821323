import { Application, Controller } from "@hotwired/stimulus"

import AutoCompleteController from "./autocomplete_controller"
import BasketController from "./basket_controller"
import MessageController from "./message_controller"
import PaymentController from "./payment_controller"
import ProductController from "./product_controller"
import SidebarController from "./sidebar_controller"
import VoucherController from "./voucher_controller"

const application = Application.start()
application.register("autocomplete", AutoCompleteController)
application.register("basket", BasketController)
application.register("message", MessageController)
application.register("payment", PaymentController)
application.register("product", ProductController)
application.register("sidebar", SidebarController)
application.register("voucher", VoucherController)
