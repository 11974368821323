import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"

class AutoCompleteController extends Controller {
  static values = {
    lookup: Array,
  }

  initialize() {
    const autoCompleteJS = new autoComplete({
      placeHolder: "Votre entreprise",
      data: {
        src: this.lookupValue,
      },
      selector: `#${this.element.getAttribute("id")}`,
      resultItem: {
        highlight: true,
      },
      events: {
        input: {
          selection: (event) => {
            const selection = event.detail.selection.value
            autoCompleteJS.input.value = selection
          },
        },
      },
    })
  }
}

export default AutoCompleteController
