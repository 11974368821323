import { Controller } from "@hotwired/stimulus"

class MessageController extends Controller {
  static targets = ["messageText"]

  connect() {
    document.addEventListener("displayMessage", (event) => {
      this.messageTextTarget.innerHTML = event.detail
      this.messageTextTarget.parentNode.classList.remove("hidden")
    })
  }
}

export default MessageController
