import { Controller } from "@hotwired/stimulus"

class ProductController extends Controller {
  static targets = [
    "quantityPicker",
    "quantityAddPicker",
    "addToCart",
    "quantity",
  ]
  addToCart() {
    const previousQuantity = parseInt(this.quantity)
    this.quantity = parseInt(this.quantity) + 1
    const event = new CustomEvent("setProductQuantity", {
      detail: {
        id: this.data.get("id"),
        quantity: this.quantity,
        previousQuantity: previousQuantity,
      },
    })
    document.dispatchEvent(event)
    this._setQuantityDisplayTo(this.quantity)
  }

  removeFromCart() {
    const previousQuantity = parseInt(this.quantity)
    this.quantity = parseInt(this.quantity) - 1

    const event = new CustomEvent("setProductQuantity", {
      detail: {
        id: this.data.get("id"),
        quantity: this.quantity,
        previousQuantity: previousQuantity,
      },
    })
    document.dispatchEvent(event)

    this._setQuantityDisplayTo(this.quantity)
  }

  _setQuantityDisplayTo(quantity) {
    if (quantity == 0) {
      this.quantityPickerTarget.classList.add("hidden")
      this.addToCartTarget.classList.remove("hidden")
    } else {
      if (this.hasQuantityPickerTarget) {
        this.quantityPickerTarget.classList.remove("hidden")
      }
      if (this.hasAddToCartTarget) {
        this.addToCartTarget.classList.add("hidden")
      }
      if (this.hasQuantityTarget) {
        this.quantityTarget.innerHTML = this.quantity
      }
    }
  }

  openSidebar(event) {
    event.preventDefault()
    const eventToSend = new CustomEvent("openSidebar", {
      detail: { id: this.data.get("id") },
    })
    document.dispatchEvent(eventToSend)
  }

  connect() {
    this.quantity = this.data.get("initialQuantity")

    document.addEventListener("setProductQuantity", (event) => {
      if (event.detail.id == this.data.get("id")) {
        this.quantity = event.detail.quantity
        this._setQuantityDisplayTo(this.quantity)
      }
    })
  }
}

export default ProductController
