import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

class PaymentController extends Controller {
  static targets = []

  _disableButton(btn) {
    btn.innerText = "Paiement en cours..."
    btn.disabled = true
  }

  _displayMessage(message) {
    const event = new CustomEvent("displayMessage", {
      detail: message,
    })
    document.dispatchEvent(event)
  }
  async startPayment(event) {
    const btn = event.currentTarget
    event.preventDefault()
    this._disableButton(btn)

    const response = await fetch(this.data.get("actionUrl"), {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        "X-RequestedWith": "JavaScript",
      },
      body: new FormData(btn.form),
    })

    if (!response.ok) {
      this._displayMessage("Une erreur est survenue, veuillez réessayer")
    }

    let jsonData = null
    try {
      jsonData = await response.json()
    } catch (e) {
      location.reload()
      return
    }

    if (jsonData.code && jsonData.code === "PAYMENT IN PROGRESS") {
      this._displayMessage(
        `Un payement est déjà en cours : <a href="${jsonData.url}">reprendre le paiement</a>`
      )
    } else {
      setTimeout(() => {
        window.open(jsonData.redirect_url, jsonData.target)
      })
    }
  }
}

export default PaymentController
